<template>
	<div>
		<loan-info-form ref="loanInfoRef" :loanInfo="loanInfo" :loanCarInfos="loanCarInfos" @setDataChange="setDataChange"></loan-info-form>
		<create-info-form :creatingInfo="creatingInfo"></create-info-form>
		<a-tabs>
			<a-tab-pane key="1" :tab="$t('table.applyInfo.tabsMenu.personal')">
				<person-info-form :personalInfo="personalInfo"></person-info-form>
			</a-tab-pane>
			<a-tab-pane key="2" :tab="$t('table.applyInfo.tabsMenu.vehicle')">
				<a-button class="add-vehicle" type="primary" @click="addLoanCar">{{ $t('page.addCar') + $t('table.applyInfo.tabsMenu.vehicle') }}</a-button>
				<a-tabs v-model="activeKey" hide-add type="editable-card" @edit="onEdit" :animated="true">
					<a-tab-pane
						v-for="(carInfo, index) in loanCarInfos"
						:key="index"
						:tab="$t('table.applyInfo.tabsMenu.vehicle') + ' ' + (index + 1)"
						:closable="index === loanCarInfos.length - 1 && index !== 0 ? true : false"
					>
						<vehicle :loanCarInfo="loanCarInfos[index]" :loanType="loanInfo.loanType" @onLogbookYearChange="onLogbookYearChange"></vehicle>
					</a-tab-pane>
				</a-tabs>
			</a-tab-pane>
			<a-tab-pane key="3" :tab="$t('table.applyInfo.tabsMenu.financial')">
				<financial :financeInfo.sync="financeInfo" :personalInfo="personalInfo"></financial>
			</a-tab-pane>
			<a-tab-pane key="4" :tab="$t('table.applyInfo.tabsMenu.application')">
				<application :loanApplyInfo="loanApplyInfo" :personalInfo="personalInfo"></application>
			</a-tab-pane>
			<a-tab-pane key="5" :tab="$t('table.applyInfo.applicationLabel.creditFile')">
				<credit-file v-on:setCreditReport="setCreditReportHandle" :creditReport="creditReport" :personalInfo="personalInfo"></credit-file>
			</a-tab-pane>
		</a-tabs>
		<div class="btn_box">
			<a-button type="primary" @click="Save" v-t.preserve="'operate.save'"></a-button>
		</div>
	</div>
</template>
<script>
import { apiPostApplyInfo } from '@/api/loan'
import LoanInfoForm from '@/views/businessComponents/LoanInfoForm'
import CreateInfoForm from '@/views/businessComponents/CreateInfoForm'
import PersonInfoForm from '@/views/businessComponents/PersonInfoForm'
import vehicle from '@/views/loanData/components/application/vehicle'
import application from '@/views/loanData/components/application/application'
import financial from '@/views/loanData/components/application/financial'
import creditFile from '@/views/loanData/components/application/creditFile'
import { debounce } from 'lodash'

export default {
	data() {
		return {
			creatingInfo: {
				cityCode: '', //城市代码
				cityName: '', //城市名称
				storeId: '', //店铺id
				storeName: '', //店铺名称
				salesmanId: '', //销售店员ID
				salesmanName: '' //销售店员名称
			},
			loanInfo: {
				loanType: 0,
				applyAmount: null,
				disposePercent: 3,
				insurancePercent: 3,
				loanProcessingFee: null,
				exciseDuty: null,
				creditLifeInsuranceFee: null,
				lessLoanBalance: 0,
				crbAmount: 500,
				transferAmount: 2100,
				gpsInstallAmount: 2500,
				gpsMonthAmount: 2500,
				mortgageRegistrationAmount: 2500,
				firstMonthDeduce: 1,
				interestRate: 0.08,
				installmentsNumbers: 3,
				insurancePremium: 0, //保险费用
				engineParticulars: 0, //发动机费用
				incharge: 0, //维护费用
				amountPayable: null,
				firstMonthRepay: 0,
				otherMonthRepay: 0,
				reductions: [],
				maintenanceFee: null, //Loan Maintenance fess
				maintenanceFeeInstallment: 600, //Loan Maintenance fess
				applicationFee: 3800, //Loan Application Fee
				kraEntryFee: 1700, //KRA Entry Fee575
				disburseType: 'choice'
			},
			loanApplyInfo: {
				id: '',
				loanId: '',
				applyFile: '',
				applyAmount: '',
				installmentsNumbers: '',
				relatives: [],
				socialAccounts: [
					{
						id: '',
						accountType: 'facebook',
						accountId: '',
						accountScreenshot: ''
					}
				],
				address: {
					id: '',
					address: '',
					location: '',
					homePics: [],
					visitReportFile: ''
				},
				hasGuarantor: 0,
				guarantor: {
					id: '',
					firstName: '',
					middleName: '',
					lastName: '',
					sex: 1,
					mobile: '',
					identityCard: '',
					identityCardFrontPic: '',
					identityCardBackPic: '',
					pin: {
						id: '',
						pinFile: '',
						taxpayerName: '',
						status: '',
						itaxStatus: '',
						itaxObligation: '',
						effectiveTime: null
					}
				},
				creditReport: null,
				supplementFiles: []
			},
			personalInfo: {
				id: '',
				loanId: '',
				basicFirstName: '',
				basicMiddleName: '',
				basicLastName: '',
				basicSex: 1,
				birthday: '',
				basicMobile: '',
				mobiles: [
					{
						mobile: ''
					}
				],
				basicIdentityCard: '',
				dateOfBirth: '',
				customerIdPic: '',
				identityCardFront: '',
				identityCardBack: '',
				identityCardHold: '',
				loanUse: '',
				incomeSource: {
					id: '',
					employmentStatus: '',
					noOfYears: '',
					salaryBand: '',
					company: '',
					employerName: '',
					employerMobile: '',
					employerEmail: '',
					file: '',
					remarks: '',
					companyAccount: '',
					salaryAccount: '',
					monthlyIncome: '',
					boardResolution: '',
					businessTradeLicence: '',
					cr2Documents: ''
				},
				pin: {
					id: '',
					pinFile: '',
					taxpayerName: '',
					pinNum: '',
					status: '',
					itaxStatus: '',
					itaxObligation: '',
					effectiveTime: null,
					currentStatus: 'Registered'
				}
			},
			financeInfo: {
				id: '',
				loanId: '',
				loanType: 0,
				invoiceFile: '',
				contractFile: '',
				sellerInstitution: '',
				sellerName: '',
				sellerMobile: '',
				sellerAccount: '',
				transcations: [],
				postDatedCheques: '',
				supplementFiles: []
			},
			loanCarInfos: [
				{
					id: '',
					loanId: '',
					make: '',
					model: '',
					year: '',
					registrationNumber: '',
					frameNumber: '',
					logbook: {
						id: '',
						logbookFile: '',
						make: '',
						model: '',
						year: '',
						registrationNumber: '',
						frameNumber: '',
						engineNo: '',
						belongs: [],
						vehicleUse: ''
					},
					insurance: {
						id: '',
						isAllRisks: '1',
						expireTime: null,
						insurancePic: '',
						insuranceQuotation: ''
					},
					pic: {
						id: '',
						appearancePics: [],
						interiorPics: [],
						chassisPics: [],
						enginePics: [],
						detailPics: []
					},
					valuation: {
						id: '',
						file: '',
						make: '',
						model: '',
						year: '',
						registrationNumber: '',
						frameNumber: '',
						engineNo: '',
						valuation: '',
						saleValuation: '',
						reportTime: null
					},
					supplementFiles: []
				}
			],
			creditReport: {
				id: null, //数据id，新增则不传
				loanId: null, //贷款id 新增则不传
				idNumber: null, //身份证号
				totalOutstandingBalance: null, //待还金额总额
				accountDefaultHistCount: null, //历史逾期交易数
				accountDefaultHistRate: null, //历史逾期交易占比
				closedAccountWithoutDefaultHistCount: null, //历史未逾期且完成交易数
				nonperformingAccountCount: null, //严重逾期交易数
				overdueAccountCount: null, //当前逾期交易数
				balanceOverdueTotal: null, //当前逾期总金额
				creditScore: null, //信用评分
				delinquencyCode: null //犯罪代码
			},
			timer: null,
			activeKey: 0,
		}
	},
	components: {
		LoanInfoForm,
		CreateInfoForm,
		PersonInfoForm,
		vehicle,
		financial,
		application,
		creditFile
	},
	methods: {
		addLoanCar() {
			this.loanCarInfos.push({
				id: '',
				loanId: '',
				make: '',
				model: '',
				year: '',
				registrationNumber: '',
				frameNumber: '',
				logbook: {
					id: '',
					logbookFile: '',
					make: '',
					model: '',
					year: '',
					registrationNumber: '',
					frameNumber: '',
					engineNo: '',
					belongs: [],
					vehicleUse: ''
				},
				insurance: {
					id: '',
					isAllRisks: '1',
					expireTime: null,
					insurancePic: '',
					insuranceQuotation: ''
				},
				pic: {
					id: '',
					appearancePics: [],
					interiorPics: [],
					chassisPics: [],
					enginePics: [],
					detailPics: []
				},
				valuation: {
					id: '',
					file: '',
					make: '',
					model: '',
					year: '',
					registrationNumber: '',
					frameNumber: '',
					engineNo: '',
					valuation: '',
					saleValuation: '',
					reportTime: null
				}
			})
			this.activeKey = this.loanCarInfos.length - 1
		},
		onEdit(targetKey, action) {
			let _this = this
			this.$confirm({
				// title: 'Do you Want to delete ' + _this.$t('table.applyInfo.tabsMenu.vehicle') + ' ' + (targetKey + 1) + '?',
				content: (h) => <div style="color:red;">Please check again to make sure.</div>,
				onOk() {
					_this[action](targetKey)
				},
				onCancel() {},
				class: 'test'
			})
		},
		remove(targetKey) {
			this.loanCarInfos.pop()
			this.activeKey = targetKey - 1
		},
		setCreditReportHandle(data) {
			this.creditReport = data
		},
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', '/home/loandata/applicationlist')
		},
		// 保存
		Save() {
			if (this.creditReport.idNumber) {
				if (this.creditReport.idNumber != this.personalInfo.basicIdentityCard) {
					this.$message.error('The ID number of the credit report is inconsistent with the ID number filled in, please re-obtain the credit report')
					return false
				}
			}
			if (!this.creatingInfo.storeId) {
				this.$message.error('Please Select Branch Office')
				return false
			}
			this.$store.commit('pageState/setUploadState', true)
			// this.loanInfo.firstMonthDeduce = 1
			let params = {
				...this.creatingInfo,
				loanInfo: this.loanInfo,
				personalInfo: this.personalInfo,
				loanCarInfos: this.loanCarInfos,
				financeInfo: this.financeInfo,
				loanApplyInfo: this.loanApplyInfo,
				creditReport: this.creditReport
			}
			// params.loanInfo.disposePercent = params.loanInfo.disposePercent * 0.01
			// params.loanInfo.insurancePercent = params.loanInfo.insurancePercent * 0.01
			apiPostApplyInfo(params)
				.then(() => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push('applicationlist')
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => {
					this.$store.commit('pageState/setUploadState', false)
				})
		},
		setDataChange(params) {
			const key = params.key.split('.')
			key.length > 1 ? (this[key[0]][key[1]] = params.value) : (this[params.key] = params.value)
		},
		onLogbookYearChange: debounce(function () {
			this.$nextTick(() => {
				this.$refs['loanInfoRef'].tryCalculate()
			})
		}, 1000),
	},
	beforeDestroy() {
		clearTimeout(this.timer)
	},
	destroyed() {
		clearTimeout(this.timer)
	}
}
</script>
<style scoped lang="less">
.ant-row.ant-form-item {
	margin-bottom: 10px;
}
.ant-input-number,
.ant-select {
	width: 200px;
}
.add-vehicle {
	margin: 10px 0;
}
</style>
